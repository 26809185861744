import { useId } from "react";
import { useFormContext } from "react-hook-form";

import Icon, { Icons } from "@/components/atoms/Icon";
import InputContainer from "@/components/forms/controls/InputContainer";
import { cn } from "@/helpers/className";
import useFormError from "@/hooks/useFormError";

interface TextInputProps
  extends Omit<React.ComponentProps<"input">, "id" | "aria-describedby"> {
  errorAfterInput?: boolean;
  hiddenLabel?: boolean;
  icon?: Icons;
  inputClassname?: string;
  label: string;
  labelClasses?: string;
  name: string;

  onBlur?: () => void;
  onChange?: () => void;
  onFocus?: () => void;
}

const TextInput = ({
  label,
  hiddenLabel = false,
  name,
  labelClasses,
  inputClassname,
  className,
  icon,
  onFocus,
  onChange,
  onBlur,
  errorAfterInput = false,
  ...props
}: TextInputProps) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  const error = useFormError(name, errors);
  const id = useId();
  const {
    onChange: handleChange,
    onBlur: handleBlur,
    name: registerName,
    ref,
  } = register(name);

  return (
    <InputContainer
      className={className}
      isError={Boolean(error)}
      error={!errorAfterInput ? error : undefined}
    >
      <label
        className={cn(
          labelClasses,
          hiddenLabel && "sr-only",
          props.required && "label-required",
        )}
        htmlFor={id}
      >
        {label}
      </label>
      <div className="relative flex w-full flex-wrap rounded">
        {icon && (
          <div
            className={cn(
              "pointer-events-none absolute inset-y-0 left-0 flex items-center pl-4",
              inputClassname?.includes("form-input-xl") && "pl-6",
            )}
          >
            <Icon
              name={icon}
              className={cn(
                "aspect-square w-5 text-black-300",
                inputClassname?.includes("form-input-xl") && "w-6",
              )}
              aria-hidden="true"
            />
          </div>
        )}
        <input
          data-error={error ? "true" : undefined}
          className={cn("form-input", icon && "pl-12", inputClassname)}
          id={id}
          {...props}
          onFocus={onFocus}
          onChange={async (e) => {
            await handleChange(e);
            onChange?.(e);
          }}
          onBlur={async (e) => {
            await handleBlur(e);
            onBlur?.(e);
          }}
          name={registerName}
          ref={ref}
        />
        {errorAfterInput && error && (
          <div className="form-error basis-full">{error}</div>
        )}
      </div>
    </InputContainer>
  );
};

export default TextInput;
