"use client";

import process from "process";
import Link from "next/link";
import { redirect, useSearchParams } from "next/navigation";
import { signIn } from "next-auth/react";
import { forgotPasswordPaths } from "routes";
import useSWRMutation from "swr/mutation";
import { z } from "zod";

import Button from "@/components/atoms/Button";
import TextInput from "@/components/forms/controls/TextInput";
import Form from "@/components/forms/Form";
import { useZodForm } from "@/hooks/useZodForm";
import { useTranslation } from "@/i18n/client";
import { AllowedLanguages } from "@/types/generic";
import Icon from "../atoms/Icon";

interface LoginFormProps {
  className?: string;
  locale: AllowedLanguages;
}

const SHOW_LOGIN_VIA_WORK_ID_BUTTON =
  process.env.NEXT_PUBLIC_SHOW_WORKID_BUTTON === "true";

export const LoginForm = ({ locale, className }: LoginFormProps) => {
  const { t } = useTranslation(locale);

  const schema = z.object({
    email: z
      .string()
      .min(1, t("error.required"))
      .email(t("error.invalid_email"))
      .toLowerCase(),
    password: z.string().min(1, t("error.required")),
  });

  const form = useZodForm({ schema });
  const searchParams = useSearchParams();

  async function login(
    url: string,
    {
      arg,
    }: {
      arg: z.infer<typeof schema>;
    },
  ) {
    return await signIn("credentials", {
      email: arg.email,
      password: arg.password,
      callbackUrl: `/${locale}/myvivaldis/dashboard`,
      redirect: false,
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const { data, trigger, isMutating } = useSWRMutation(
    "/api/auth/callback/credentials",
    login,
  );

  const handleLinkedInLogin = () => {
    signIn("linkedin");
  };

  const handleFacebookLogin = () => {
    signIn("facebook");
  };

  const handleWorkIDLogin = () => {
    signIn("workid");
  };

  const handleSubmit = async (data: z.infer<typeof schema>) => {
    await trigger(data);
  };

  if (data?.ok) {
    if (data.url) {
      window.location.href = data.url;
    } else {
      redirect(`/${locale}/myvivaldis/profile`);
    }
  }

  return (
    <div className={className}>
      <h2 className="h3 !mb-6">{t("login.form_title")}</h2>
      {searchParams.get("password_reset") && (
        <div className="mb-4 rounded bg-positive-100 px-4 py-3">
          <h6 className="!mb-0">{t("login.password_reset_success.title")}</h6>
          <div>{t("login.password_reset_success.message")}</div>
        </div>
      )}
      {searchParams.get("password_set") && (
        <div className="mb-4 rounded bg-positive-100 px-4 py-3">
          <h6 className="!mb-0">{t("login.password_set_success.title")}</h6>
          <div>{t("login.password_set_success.message")}</div>
        </div>
      )}
      {searchParams.get("user_activated") && (
        <div className="mb-4 rounded bg-positive-100 px-4 py-3">
          <h6 className="!mb-0">{t("login.user_activated_success.title")}</h6>
          <div>{t("login.user_activated_success.message")}</div>
        </div>
      )}

      {/* Temporary message */}
      {!searchParams.get("password_reset") &&
        !searchParams.get("user_activated") && (
          <div className="mb-6 flex rounded border border-yellow px-4 py-3">
            <Icon
              className="mr-3 mt-0.5 h-4 w-4 shrink-0 text-yellow-600"
              name="info"
            />
            <div
              className="prose max-w-full text-xs text-black sm:text-sm"
              dangerouslySetInnerHTML={{
                __html: t("login.new_website_reset_password.message"),
              }}
            ></div>
          </div>
        )}

      <Form
        form={form}
        className="flex flex-col gap-6"
        onSubmit={handleSubmit}
        noValidate={true}
      >
        <TextInput
          label={t("login.email.label")}
          placeholder={t("login.email.placeholder")}
          name="email"
          type="email"
          required
        />

        <TextInput
          label={t("login.password.label")}
          placeholder={t("login.password.placeholder")}
          name="password"
          type="password"
          required
        />

        {data?.ok === false && (
          <div className="text-negative">
            {data.error === "not_activated"
              ? t("login.error.not_activated")
              : t("login.error.invalid_credentials")}
          </div>
        )}

        <div className="mt-4 flex flex-wrap items-center gap-x-10 gap-y-6 lg:mt-6">
          <Button
            type="submit"
            disabled={isMutating}
            hasLoader={true}
            isLoading={isMutating}
            className="btn-lg"
          >
            {t("login.submit")}
          </Button>

          <Link
            href={`/${locale}/myvivaldis/${forgotPasswordPaths[locale]}`}
            className="link"
          >
            {t("login.forgot_password")}
          </Link>
        </div>
        <div className="mt-4 flex items-center">
          <div className="border-1 flex-grow border-t border-dashed"></div>
          <div className="px-3 uppercase text-black-300 lg:px-4">
            {t("login.or")}
          </div>
          <div className="border-1 flex-grow border-t border-dashed"></div>
        </div>
        <div className="mt-4 flex flex-wrap items-center gap-x-6 gap-y-4">
          {/* LinkedIn Login Button */}
          <Button
            type="button"
            icon={"linkedin"}
            iconClassName={"text-[#0077B5] -translate-y-[.14rem]"}
            arrow={false}
            onClick={handleLinkedInLogin}
            className="btn btn-social-login btn-social-login--linkedin btn-linkedin 0 w-full gap-2 border-border bg-white font-semibold text-black hover:bg-grey-100"
          >
            {t("login.linkedin")}
          </Button>

          {/* Facebook Login Button */}
          <Button
            type="button"
            icon={"facebook"}
            iconClassName={"text-[#316FF6] -translate-y-[.1rem]"}
            arrow={false}
            onClick={handleFacebookLogin}
            className="btn btn-facebook btn-social-login--facebook btn-linkedin 0 w-full gap-2 border-border bg-white font-semibold text-black hover:bg-grey-100"
          >
            {t("login.facebook")}
          </Button>

          {/* WorkID Login Button */}
          {SHOW_LOGIN_VIA_WORK_ID_BUTTON ? (
            <Button
              type="button"
              icon={"workid"}
              iconClassName={"text-[#316FF6] -translate-y-[.1rem]"}
              arrow={false}
              onClick={handleWorkIDLogin}
              className="btn btn-workid btn-social-login--workid 0 w-full gap-2 border-border bg-white font-semibold text-black hover:bg-grey-100"
            >
              {t("login.work_id")}
            </Button>
          ) : null}
        </div>
      </Form>
    </div>
  );
};
